.reports-invoice-item-container {
    display: flex;
    flex-direction: column;
    padding: 13px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.08);
    gap: 10px;
    position: relative;
}

.reports-invoice-item-container-section-1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.invoice-actions-section {
    border-radius: 8px;
    transition: max-height 1s ease, opacity 1s ease;
    // max-height: 200px;
    opacity: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    margin-top: 10px;
}

.invoice-actions-section.closing {
    max-height: 0;
    opacity: 0;
}