.checkbox-tools:checked+label,
.checkbox-tools:not(:checked)+label {
    position: relative;
    display: inline-block;
    padding: 12px;
    line-height: 10px;
    width: 100%;
    font-family: 'Inter';
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.checkbox-tools:checked+label {
    border-color: #ff0c7c;
    color: #ff0c7c;
    background-color: #ff64aa53;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
}