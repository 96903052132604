#loading-overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 99999;
  background: rgba(82, 114, 255, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.lds-ripple {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.9, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 100px;
    left: 100px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}
