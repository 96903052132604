.employee-dash-benefits-option {
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.selected {
        color: #5D0070;
    }
}

.employee-dash-benefits-option-hr {
    color: #5D0070;
    width: 100%;
    height: 3px;
    border: none;
    background-color: #5D0070;
}

.employee-dash-my-benefit-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    border-radius: 16px;
    padding: 20px;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.21);
}

.employee-dash-explore-benefit-container {
    flex-direction: column;
    justify-content: flex-start;
    gap: 6px;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
    background: #FFF;
    display: flex;
    padding: 12px 20px;
    align-items: center;
}

.employee-dash-benefit-img {
    width: 80px;
}

.fixed-top-div {
    background: #FFF;
    position: sticky;
    top: 0;
}