.back-arrow-btn {
    border-radius: 8px;
    border: 1px solid #D8C9DB;
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    width: fit-content;
}

.employee-dash-header {
    color: #190829;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.subheader {
        font-size: 15px;
    }
}

.profile-details {
    font-size: 13px;

    &.label {
        color: #74738D;

    }

    &.indented {
        margin-left: 12px;
    }

    &.value {
        font-weight: 600;
        color: #001949;
    }
}

.profile-changes-btn {
    display: flex;
    border: none;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;

    &.confirm {
        border-radius: 16px;
        background: #5D0070;
        color: #fff;
    }

    &.cancel {
        font-weight: 500;
        border-radius: 24px;
        background: #FFF;
    }
}

.profile-changes-input {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    padding: 12px 72px 12px 12px;
    align-items: center;
    border: none;
    border-radius: 8px;
    background: #f3eaf2;
}

.profile-update-success {
    border-radius: 12px;
    border: 1px solid #EA2199;
    background: #FFF2FA;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    display: inline-flex;
    padding: 20px;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.profile-dropdown {
    font-family: "Inter";
    border-radius: 200px;
    font-size: 13px;
    display: flex;
    padding: 12px 72px 12px 12px;
    align-items: center;
    border: none;
    border-radius: 8px;
    background: #f3eaf2;
}