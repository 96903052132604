.App {
    margin: 0;
}

.two-buttons-container-pri-purinv {
    width: 100%;
    padding: 10px;
    display: flex;
    border-radius: 12px;
    background: #FDF7FF;
    justify-content: space-between;
}

.employee-benefits-assessment-header {
    color: #5D0070;
    text-align: center;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.employee-benefits-assessment-question-header {
    color: #EA2199;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Epilogue;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.employee-benefits-assessment-question-container {
    height: 65vh;
    gap: 25px;
    display: flex;
    margin: auto;
    width: 60vw;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.employee-benefits-assessment-question-title {
    padding: 30px 0px;
    color: #010143;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-right: auto;
    line-height: 150%;
}

.employee-benefits-assessment-options-container {
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.employee-benefits-assessment-options-item {
    cursor: pointer;
    border-radius: 16px;
    width: 50%;
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border: 1px solid #5D0070;
    color: #5D0070;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    &.selected {
        border: 1px solid #ffffff;
        color: #ffffff;
        background: #5D0070;
    }
}

.employee-benefits-assessment-multiple-choice-choices-container {
    margin-bottom: 50px;
}

.employee-benefits-assessment-multiple-choice-choices-header {
    color: #212121;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.employee-benefits-assessment-multiple-choice-choices-item {
    color: #5B5B5B;
    padding: 2px 10px;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
}

.employee-benefits-assessment-multiple-choice-choices-item-checkbox {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #5D0070;
    cursor: pointer;
    position: relative;
    padding: 7px;

    &:checked {
        background-color: #5D0070;
        color: #ffffff;

        &::before {
            content: '\2714'; // Unicode checkmark character
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 12px; // Adjust the font size of the check icon
        }
    }

}

.employee-benefits-assessment-submit-header {
    color: #5D0070;
    text-align: center;
    font-family: Epilogue;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.employee-benefits-assessment-submit-subheader {
    color: #010143;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.employee-benefits-assessment-submit-inputs-container {
    padding: 20px 0px;
    gap: 15px;
    display: grid;
    justify-items: center;
    align-items: center;
    align-content: center;
}

.employee-benefits-assessment-employee-benefits-budget-container {
    border-radius: 24px;
    background: #F2F8FA;
    padding: 0px 10px 20px;
    margin: 20px 0px;
}

.employee-benefits-assessment-employee-benefits-budget-input {
    border-radius: 16px;
    border: 1px solid #DADADA;
    background: #FFF;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 12px;
}