.multiple-choice-option-card {
    border-radius: 12px;
    border: 1.5px solid #001949;
    background: #FFF;
    display: inline-flex;
    height: fit-content;
    width: 100%;
    padding: 5px 10px;
    justify-content: center;
    align-items: left;
    flex-shrink: 0;

    &.checked {
        border-radius: 12px;
        border: 1.5px solid #8243D5;
        background: rgba(130, 67, 213, 0.10);
    }
}

.multiple-choice-options-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 80vw;
}

.multiple-choice-question-header {
    color: #ff64ab;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.15px;
}