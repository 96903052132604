@import '../../../shared/variables';

.confirmContent {
    max-height: 50vh;
    overflow: scroll;
}

.confirmModal .modal-content .modal-body {
    text-align: left !important;
    padding: 10px 20px !important;
}

.cursorSelect {
    cursor: pointer;
}

.inpControls {
    margin-top: 25px;
}

.inp {
    margin-bottom: 25px;
}

.txtlead {
    text-align: left;
}



.chbTc {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-top: -15px;
}

.steps {
    background-color: $body-bg-darker;
    width: 100px;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.viewContent {
    padding: 1em 1.2rem !important;
    height: 90vh;

}

.custom-field {
    position: relative;
    font-size: 14px;
    padding-top: 20px;
    width: 100%;

}

.custom-field input {
    border-bottom: 2px solid #ddd;
    border-top: none;
    border-left: none;
    border-right: none;
    appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    background: #f2f2f2;
    padding: 12px;
    border-radius: 3px;
    width: 100%;
    outline: none;
    font-size: 14px;
}

.custom-field .placeholder {
    position: absolute;
    left: 12px;
    top: calc(50%);
    transform: translateY(-50%);
    color: #aaa;
    transition:
        top 0.3s ease,
        font-size 0.3s ease,
        color 0.3s ease;
}

.custom-field input:valid+.placeholder {
    top: 10px;
    font-size: 10px;
    color: #222;
}

.custom-field input:focus+.placeholder {
    top: 10px;
    font-size: 10px;
    color: #4c1ba5;
}

.custom-field input:focus {
    border-color: #4c1ba5;
}

Button {
    background-color: #4c1ba5;
}

.backButton {
    color: #4c1ba5;
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
}

// // Checkbox

// .container {
//     display: block;
//     position: relative;
//     padding-left: 30px;
//     margin-bottom: 12px;
//     cursor: pointer;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     //padding-top: 2px;
//   }

//   /* Hide the browser's default checkbox */
//   .container input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;

//   }

//   /* Create a custom checkbox */
//   .checkmark {
//     position: absolute;
//     top: 3px;
//     left: 0;
//     height: 20px;
//     width: 20px;
//     border-radius: 5px;
//     background-color: #ffffff;
//     //border: 1px solid #242424;
//   }

//   /* On mouse-over, add a grey background color */
//   .container:hover input ~ .checkmark {
//     //background-color: #ccc;
//   }

//   /* When the checkbox is checked, add a blue background */
//   .container input:checked ~ .checkmark {
//     background-color: #de5997;
//   }

//   /* Create the checkmark/indicator (hidden when not checked) */
//   .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
//   }

//   /* Show the checkmark when checked */
//   .container input:checked ~ .checkmark:after {
//     display: block;
//   }

//   /* Style the checkmark/indicator */
//   .container .checkmark:after {
//     left: 7px;
//     top: 4px;
//     width: 5px;
//     height: 10px;
//     border: solid white;
//     border-width: 0 3px 3px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
//   }

//   //Modal Window
//   .modal-window {
//     position: fixed;
//     background-color: rgba(255, 255, 255, 0.25);
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     z-index: 999;
//     visibility: hidden;
//     opacity: 0;
//     pointer-events: none;
//     transition: all 0.3s;
//     &:target {
//       visibility: visible;
//       opacity: 1;
//       pointer-events: auto;
//     }
//     & > div {
//       width: 400px;
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       padding: 2em;
//       background: white;
//     }
//     header {
//       font-weight: bold;
//     }
//     h1 {
//       font-size: 150%;
//       margin: 0 0 15px;
//     }
//   }

//   .modal-close {
//     color: #aaa;
//     line-height: 50px;
//     font-size: 80%;
//     position: absolute;
//     right: 0;
//     text-align: center;
//     top: 0;
//     width: 70px;
//     text-decoration: none;
//     &:hover {
//       color: black;
//     }
//   }