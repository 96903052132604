@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset some default styles for consistency across browsers */
body,
h1,
h2,
h3,
ul,
li {
  margin: 0;
  padding: 0;
}

/* Apply a box-sizing border-box model to all elements */
* {
  box-sizing: border-box;
}

/* Set a global font family and default font size */
body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  background-color: #ffffff;
}

button {
  cursor: pointer;
}

/* Add some spacing to elements */
h1,
h2,
h3 {
  margin-bottom: 20px;
}

hr {
  margin-bottom: 20px;
  margin-top: 20px;
  margin: revert-layer;
}

p {
  color: #190829;
  font-size: 13px;
  font-weight: 500;
  font-family: 'Inter';
}

img {
  max-width: fit-content;
}

/* Style links */
a {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

.header {
  color: #5D0070;
  text-align: center;
  font-family: Epilogue;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.subheader {
  color: #010143;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pwaPrompt {
  width: 100%;
  height: 55px;
}

.pwaPromptCard {
  height: 55px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
}

.pwaImageDiv {
  background-color: #000000;
  border-radius: 5px;
  height: 45px;
  width: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
}

.pwaImage {
  width: 24px;
  height: width;
}

.pwaContent {
  display: flex;
  align-items: center;
  height: 100%;
}

.pwaContent p {
  margin-bottom: 0px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
}

.pwaContent button {
  height: 40px;
  width: 100px;
  text-align: center;
  margin-right: 10px;
}

/* Add padding to the main content area */
main {
  gap: 15px;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}