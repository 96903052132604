.cart-container {
    display: flex;
    flex-direction: column;
}

.cart-container-item {
    border: 1px solig gray;
    border-radius: 17px;

    p {
        margin: 0;
    }
}