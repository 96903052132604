.flexible-pay-radial-svg {
    height: 10%;
    margin: auto;
    display: block;
}

path {
    stroke-linecap: round;
    stroke-width: 6;
}

path.grey {
    stroke: rgba(168, 56, 250, 0.11);
}

path.blue {
    stroke-dasharray: 130;
    animation: dash 3s ease-out forwards;
}

.withdrawTxtHead {
    fill: #3734C8;
    text-align: center;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.withdrawTxtSub {
    font-family: Inter;
    font-size: 30%;
    fill: #868685;
}

.flexible-pay-dash-btn {
    border-radius: 100px;
    background: #221663;
    color: #fff;
    padding: 20px 32px;
    border: none;
    width: 100%;

    &.inverted {
        background: #fff;
        color: #221663;
    }
}

.benefit-dash-fixed-top-div {
    background: #FFF;
    z-index: 100;
    position: sticky;
    top: 0;
    display: flex;
    padding: 11px 20px 15px 20px;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 8px 15px -8px rgba(130, 67, 213, 0.3);
}

.flexible-pay-history-item-container {
    display: flex;
    padding: 13px;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.08);
    justify-content: space-between;
}

.confirm-advance-table-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dadada;
    padding: 4px 10px;

    &.noborder {
        border-bottom: none;
    }

    p {
        margin: 0;
    }
}