.signin-main-header {
    color: #010143;
    font-family: Epilogue;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: auto;
}

.signin-inputs-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.signin-input-label {
    color: #010143;
    font-family: Inter;
    font-size: 11.866px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.signin-input-item {
    margin-top: 2px;
    font-size: 16px;
    border: none;
    padding: 15px 18px;
    font-family: Inter;
    border-radius: 3.955px;
    background: #F2F2F2;
}

.disclamer-text {
    color: #646464;
    font-family: Inter;
    font-size: 12.657px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    a {
        color: #010143;
        font-family: Inter;
        font-size: 12.657px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
    }
}

.forgot-password-text {
    color: #010143;
    font-family: Inter;
    font-size: 12.657px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.signin-sign-in-btn {
    text-align: center;
    border: none;
    padding: 10px;
    font-family: Inter;
    color: white;
    border-radius: 3.955px;
    background: #010143;
    cursor: pointer;
}

.signin-register-in-btn {
    text-align: center;
    padding: 10px;
    font-family: Inter;
    border-radius: 3.955px;
    border: 0.791px solid #EDEDED;
    background: #FFF;
    color: #010143;
    box-shadow: 0px 3.164px 20.567px 0px rgba(0, 0, 0, 0.05);
}