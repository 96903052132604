.admin-dash-sidebar-container {
    border-top: none;
    border-right: 1px solid #CFDDEE;
    width: fit-content;
    padding: 80px 20px;
    height: 90vh;

}

.admin-dash-sidebar-container-items-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.admin-dash-sidebar-text {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #6B6B6B;
    cursor: pointer;

    &.bold {
        color: #202020;
        font-weight: 700;
    }

    &.text {
        cursor: none;
    }

    &.grayed {
        color: #6b6b6b33
    }
}

.admin-dash-topbar {
    display: flex;
    border: 1px solid #CFDDEE;
    width: 100vw;
    padding: 20px;
    height: 10vh;
    justify-content: space-between;
    align-items: center;
}

.admin-dash-option-header {
    color: #282828;
    font-family: Inter;
    font-size: 40px;
    font-weight: 600;
    line-height: 48.41px;
    text-align: left;
}

.admin-dash-filter-item {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #282828;
    cursor: pointer;

    &.selected {
        text-decoration: underline 2.5px;
        text-underline-offset: 15px;
    }
}

.admin-dash-sub-header {
    font-family: Inter;
    font-size: 22px;
    font-weight: 600;
    line-height: 26.63px;
    text-align: left;
    color: #616B78;
}

.admin-dash-users-table-headers {

    background: #F8F8F8;
    display: flex;
    padding: 10px;
    border-top: 1px solid #80808061;
    border-bottom: 1px solid #80808061;

    &.white {
        background: #ffffff;
        border-top: none;
        cursor: pointer;
    }
}

.admin-dash-users-table-headers-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #686868;

    &.bold {
        font-weight: 800;
    }

    &.left {
        width: 350px;
    }

    &.right {
        width: 200px;
    }

    &.pending {
        color: #DE5101;
    }

    &.active {
        color: #0156DE;
    }

    &.deactivated {
        color: red;
    }
}

.admin-dash-add-btn {
    padding: 10px 20px;
    border-radius: 15px;
    color: #ffffff;
    background: black !important;
    font-family: 'Inter';
    cursor: pointer;
}

.hover {
    :hover {
        background: #FFE4CB;
    }
}

.admin-dash-search-input {
    border-radius: 5px;
    padding: 10px 15px;
    background: rgb(248, 248, 248);
    border: 1px solid #ffa50040;
    width: 100%;
    font-family: Inter;
    font-size: 12px;
    line-height: 14.52px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #686868;
}

.admin-dash-back-arrow-btn {
    border: 1px solid #E1E1E1;
    background-color: white;
    padding: 10px 15px;
    border-radius: 15px;
    color: #000B32;
    font-family: 'Inter';
    cursor: pointer;
}

.admin-dash-blue-clients-text {
    font-family: Inter;
    font-size: 11px;
    font-weight: 600;
    text-align: left;
    color: #0156DE;
    cursor: pointer;

    &.normal {
        font-size: 14px;
    }
}

.admin-dash-toggle-active-status {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 20px;
    transition: background-color 0.3s;
    cursor: pointer;
}

.toggle::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: left 0.3s;
}

.admin-dash-toggle-active-status:checked+.toggle {
    background-color: #2196F3;
    /* Change to desired active color */
}

.admin-dash-toggle-active-status:checked+.toggle::after {
    left: 22px;
}

.admin-dash-client-assessments-section {
    padding: 5px 5px;
    width: 100%;
    gap: 100px;
    display: flex;
    background: #F5F5F5;
    align-items: center
}

.employer-dash-overview-benefits-container {
    padding: 20px;
    border-radius: 24px;
    border: 2px solid #5d0070;
    background: #FFF;
    box-shadow: 0px 4px 17px 0px #5d00704d;
}