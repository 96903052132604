.admin-dash-client-details-text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #6B6B6B;

    &.bold {
        color: #202020;
        font-weight: 900;
    }

    &.small {
        color: #707070;
        font-size: 13px;
        text-align: left;
    }

    &.mediumbold {
        color: #202020;
        font-weight: 700;
    }

    &.big {
        font-size: 20px;
        margin-bottom: 8px;
    }

    &.button {
        padding: 10px;
        border-radius: 5px;
        background: #80808057;
        cursor: pointer;
    }
}

.admin-dash-assess-q-a-item {
    width: 80%;
    margin-bottom: 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;

    &.half {
        width: 50%;
        padding: 5px;
    }
}

.admin-dash-assess-input {
    font-family: 'Inter';
    border: 1px solid #808080ab;
    padding: 10px;
    border-radius: 5px;

    &.bold {
        font-weight: 700;
    }
}

.admin-dash-add-client-details-container {
    display: flex;
    flex-wrap: wrap;
}

.two-by-two-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.flex-column {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.admin-dash-edit-inner-container {
    border-radius: 20px;
    margin: auto;
    padding: 50px;
    background: white;
}

.admin-dash-dropdown-clients {
    padding: 5px 20px;
    font-family: 'Inter';
    font-size: 12px;
    border-radius: 10px;
}