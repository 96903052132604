.employer-agreement-paragraph {
    padding: 25px;
    width: 100%;
    height: 50vh;
    overflow-y: scroll;
    color: #010143;
    padding: 20px;
    font-size: 14px;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #D0C9C9;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }
}