.client-benefits-filter-section {
    gap: 20px;
    display: flex;
    width: 100%;
    align-items: center;
}

.client-benefits-filter-item {
    border-radius: 33px;
    border: 1px solid #282828;
    background: #FFF;
    font-family: 'Inter';
    font-size: 12px;
    color: #282828;
    padding: 8px 16px;
    cursor: pointer;

    &.selected {
        border: none;
        background: #282828;
        color: #fff;
    }
}

.client-benefits-container-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}