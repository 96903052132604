.credit-builder-forms {
    display: flex;
    justify-content: center;
    flex-direction: column;

    label {
        input {
            border: 1px solid black;
        }
    }
}