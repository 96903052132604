.benefits-report-main-header {
    font-family: Epilogue;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.6px;
    text-align: left;
    color: #FFFFFF;

    &.medium {
        font-size: 18px;
    }

    &.small {
        font-size: 14px;
    }

    &.lighter {
        font-weight: 500;
    }

    &.black {
        color: black;
    }

    &.blue {
        color: #001949;
    }

    &.selected {
        color: #001949;
        background-color: #FFFFFF;
    }

    &.nav {
        padding: 5px;
        cursor: pointer;

        &:hover {
            color: #001949;
            background-color: #FFFFFF;
        }
    }

}

.benefits-report-copy-container {
    border-radius: 50px;
    border: 1px solid rgb(0 0 0 / 9%);
    padding: 40px;
    width: 40%;
    box-shadow: 1px 1px 15px 1px rgba(128, 128, 128, 0.1607843137);
    line-height: 1.5;
    height: fit-content;
}

.benefits-report-donut-perc-text {
    font-family: Epilogue;
    font-size: 40px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;

    &.purple {
        color: #5D0070;
    }

    &.blue {
        color: #0033C0
    }
}

.benefits-report-demographic-item-container {
    box-shadow: 1px 1px 15px 1px #80808029;
    border-radius: 30px;
    padding: 20px 50px;
    border: 1px solid #00000017;
    margin-bottom: 2%;
}

.benefits-report-grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    gap: 0;
    /* Set gap to 0 to make borders align properly */
    width: max-content;
    border-collapse: collapse;
    /* Ensure borders collapse like a table */
    border: 1px solid #ffffff;
    font-family: 'Epilogue';
    /* Add a border to the container if needed */
}

.benefits-report-grid-item {
    border: 1px solid #ffffff;
    /* Add borders to grid items */
    padding: 10px;
    /* Add padding for better appearance */
    box-sizing: border-box;
    /* Ensure padding does not affect the width */
    color: #FFFFFF;
}

.benefits-report-findings-textarea {
    border-radius: 10px;
    border: 1px solid rgb(0 0 0 / 9%);
    padding: 40px;
    box-shadow: 1px 1px 15px 1px rgba(128, 128, 128, 0.1607843137);
    line-height: 1.5;
    height: fit-content;
    color: #202020;
    font-size: 13px;
    text-align: left;
    font-weight: 600;
    line-height: 20px;
    font-family: 'Inter';
    width: 100%;
    height: 20rem;
}