.employee-dash-home-widget-container {
border-radius: 20px;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.employee-dash-home-widget-header {
    color: #003132;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.employee-dash-fin-health-overall-circle {
    background: #fff;
    height: 75px;
    width: 75px;
    font-weight: 500;
    font-size: 36px;
    border-radius: 50%;
    position: absolute;
    padding:10px;
    color:#EA1299;
    left:calc(50% - 37.5px);
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    border: 1px solid #EA219936;
}

.employee-dash-warning-widget {
    border-radius: 16px;
    font-size: 13px;
    background: #FFF0E5;
    font-weight: 600;
    display: flex;
    padding: 7px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    color: #CD7900;

    &.error {
        background: linear-gradient(102deg, #B50303 1.32%, #941010 95.08%);
        color: #fff;
    }
}

.employee-dash-fin-health-quadrants-container {
    border-radius: 40px;
    background: #FFF2FA;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.employee-dash-fin-health-quadrants-score-item {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &.top-left {
        padding-bottom: 10px;
        border-right: 2px solid #EA219936;
        border-bottom: 2px solid #EA219936;
    }

    &.top-right {
        padding-bottom: 10px;
        border-left: 2px solid #EA219936;
        border-bottom: 2px solid #EA219936;
    }

    &.bottom-left {
        padding-top: 10px;
        border-right: 2px solid #EA219936;
        border-top: 2px solid #EA219936;
    }

    &.bottom-right {
        padding-top: 10px;
        border-left: 2px solid #EA219936;
        border-top: 2px solid #EA219936;
    }
}