.navbar-dropdown-section {
    padding: 10px;
    top: 55px;
    border-radius: 12px;
    background: #202020;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    width: 170px;
    position: absolute;
}

.employee-profile-dropdown {
    padding: 10px;
    bottom: 55px;
    border-radius: 12px;
    background: #202020;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    right: 5px
}

.bottom-nav-label {
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #190829;

    &.selected {
        color: #EA2199;
    }
}

.bottom-nav-label-flexible-pay {
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #fff;

    &.selected {
        color: #C270FF;
    }
}