.admin-dash-sidebar-container {
    border-top: none;
    border-right: 1px solid #CFDDEE;
    width: fit-content;
    padding: 80px 20px;
    height: 90vh;

}

.admin-dash-sidebar-container-items-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.admin-dash-sidebar-text {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #6B6B6B;
    cursor: pointer;

    &.bold {
        color: #202020;
        font-weight: 700;
    }

    &.text {
        cursor: none;
    }

    &.grayed {
        color: #6b6b6b33
    }
}

.admin-dash-topbar {
    display: flex;
    border: 1px solid #CFDDEE;
    width: 100vw;
    padding: 20px;
    height: 10vh;
    justify-content: space-between;
    align-items: center;
}

.admin-dash-option-header {
    color: #282828;
    font-family: Inter;
    font-size: 40px;
    font-weight: 600;
    line-height: 48.41px;
    text-align: left;
}

.admin-dash-filter-item {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #282828;
    cursor: pointer;

    &.selected {
        text-decoration: underline 2.5px;
        text-underline-offset: 15px;
    }
}

.admin-dash-sub-header {
    font-family: Inter;
    font-size: 22px;
    font-weight: 600;
    line-height: 26.63px;
    text-align: left;
    color: #616B78;
}

.admin-dash-users-table-headers {

    background: #F8F8F8;
    display: flex;
    padding: 10px;
    border-top: 1px solid #80808061;
    border-bottom: 1px solid #80808061;

    &.white {
        background: #ffffff;
        border-top: none;
        cursor: pointer;
    }
}

.admin-dash-users-table-headers-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #686868;

    &.bold {
        font-weight: 800;
    }

    &.left {
        width: 350px;
    }

    &.right {
        width: 200px;
    }
}

.admin-dash-add-btn {
    font-size: 13px;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #ffffff;
    background: black !important;
    font-family: "Inter";
    cursor: pointer;
}

.hover {
    :hover {
        background: #FFE4CB;
    }
}