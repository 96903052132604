.radial-chart-baseline-svg {
    height: 10%;
    margin: auto;
    display: block;
}

path {
    stroke-linecap: round;
    stroke-width: 3;
}

path.basline-radial-chart-grey {
    stroke: #0101011c;
    stroke-width: 3;
}

path.basline-radial-chart-blue {
    stroke-dasharray: 130;
    animation: dash 3s ease-out forwards;
    stroke-width: 3;
}

.basline-radial-chart-withdrawTxtHead {
    fill: #003132;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.basline-radial-chart-withdrawTxtSub {
    font-family: Inter;
    font-size: 30%;
    fill: #868685;
}