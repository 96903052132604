.confirm-employee-benefits-metrics-card {
    border-radius: 16px;
    gap: 21px;
    display: flex;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
    width: 250px;
    height: 107px;
    padding: 0px 12px;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0px;
}

.confirm-employee-benefits-metrics-card-text-header {
    color: #010143;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%;

}

.confirm-employee-benefits-metrics-card-text-info {
    color: #010143;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.confirm-employee-benefits-metrics-container {
    display: flex;
    gap: 50px;
    margin: 20px 0px;
}

.confirm-employee-benefits-metrics-card-text-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.confirm-employee-benefits-benefit-description-card {
    border-radius: 16px;
    border: 1px solid #E8DCEB;
    background-color: #FEF8FF;
    width: 100%;
    margin: auto;
}

.confirm-employee-benefits-benefit-description-card-header {
    color: #5D0070;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;

    & span {
        font-size: 12px;
        font-weight: 300;
    }
}

.confirm-employee-benefits-benefit-description-card-header-container {
    display: flex;
    justify-content: space-between;
    background-color: #FEF8FF;
    padding: 10px 30px;
    border-radius: 16px 16px 0px 0px;
}

.confirm-employee-benefits-benefit-description-card-section2 {
    border-radius: 16px;
    padding: 10px 35px;
    border: 1px solid #E8DCEB;
    background: #FFF;
}

.confirm-employee-benefits-benefit-description-card-type-container {
    display: flex;
    justify-content: flex-start;
    gap: 50px;
    padding: 0px 0px 0px 0px
}

.confirm-employee-benefits-benefit-description-card-type-text {
    color: #737373;
    font-size: 12px;

    & span {
        color: #000;
    }
}

.confirm-employee-benefits-benefit-description-card-description-container {
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
    background: #FFF;
    display: flex;
    padding: 12px 20px;
    align-items: center;
}

.confirm-employee-benefits-benefit-description-card-description-text-container {
    gap: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.two-buttons-container-pri-one-btn-right {
    width: 100%;
    padding: 10px;
    display: flex;
    gap: 15px;
    align-items: center;
    border-radius: 12px;
    background: #FDF7FF;
    justify-content: flex-end;
}