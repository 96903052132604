.connect-payroll-providers-container {
    gap: 40px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.connect-payroll-providers-card {
    display: flex;
    box-sizing: border-box;
    cursor: pointer;
    width: 320px;
    min-width: 280px;
    height: 145px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);

    &.active {
        border: 3px solid #EA2199;
        box-shadow: 0px 4px 24px 0px rgba(234, 33, 153, 0.12);
    }

}

.payroll-client-card {
    display: flex;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 500;
    width: 320px;
    min-width: 280px;
    height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #80808069;

    &.active {
        border: 3px solid #EA2199;
        box-shadow: 0px 4px 24px 0px rgba(234, 33, 153, 0.12);
    }

}

.connect-payroll-providers-card-check {
    padding: 8px;
    margin-right: auto;
    margin-bottom: auto;
}

.upload-payroll-file-container {
    display: flex;
    width: 100%;
    padding: 56px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 10px;
    border: 1px dashed rgba(0, 0, 0, 0.25);
}

.upload-payroll-file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px dashed rgba(0, 0, 0, 0.25);
    padding: 20px;
    background: #FBFDFE;
    text-align: center;
    position: relative;
    transition: background-color 0.3s, border-color 0.3s;
}

.upload-payroll-file-container.dragging {
    border-color: #000;
    background-color: #f0f0f0;
}

.upload-payroll-file-select-file-btn {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 98, 255, 0.70);
    background: #FBFDFE;
    font-family: "Inter";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.approve-employee-card {
    width: 100%;
    border-radius: 10px;
    background: #b1b1b124;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.payroll-approve-table-container {
    width: 95%;
    border-radius: 23px;
    padding: 35px;
    background: rgba(250, 250, 250, 0.87);
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    box-shadow: -7px -4px 7px rgba(0, 0, 0, 0.08);
}

.payroll-approve-table-item {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}