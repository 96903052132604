.benefit-utilisation-graph-container {
    border-radius: 16px;
    border: 1px solid #CFDDEE;
    background: #FFF;
    display: flex;
    align-items: center;
    padding: 15px;
    width: fit-content;

}

.benefit-utilisation-graph-out-off-text {
    color: #0062FF;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}